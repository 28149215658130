import { Stack } from '@mui/material';

import FAQKhoraHomeSection1 from './khorahome/Section1';
import FAQKhoraHomeSection2 from './khorahome/Section2';
import FAQKhoraHomeSection3 from './khorahome/Section3';
import FAQKhoraHomeSection4 from './khorahome/Section4';
import FAQKhoraHomeSection5 from './khorahome/Section5';
import FAQKhoraHomeSection6 from './khorahome/Section6';
import FAQKhoraHomeSection7 from './khorahome/Section7';
import FAQKhoraHomeSection8 from './khorahome/Section8';
import FAQKhoraHomeSection9 from './khorahome/Section9';
import FAQKhoraHomeSection10 from './khorahome/Section10';
import FAQKhoraHomeSection11 from './khorahome/Section11';

const LandingFAQKhoraHomeSection = () => {
  return (
    <Stack spacing={1.25} sx={{ maxWidth: 1140, margin: 'auto', pb: { xs: 5, md: 10 }, px: 2.5 }}>
      <FAQKhoraHomeSection1 />
      <FAQKhoraHomeSection2 />
      <FAQKhoraHomeSection3 />
      <FAQKhoraHomeSection4 />
      <FAQKhoraHomeSection5 />
      <FAQKhoraHomeSection6 />
      <FAQKhoraHomeSection7 />
      <FAQKhoraHomeSection8 />
      <FAQKhoraHomeSection9 />
      <FAQKhoraHomeSection10 />
      <FAQKhoraHomeSection11 />
    </Stack>
  );
};
export default LandingFAQKhoraHomeSection;
