import { useNavigate } from 'react-router-dom';
import { Stack, Box, Typography, Button, useTheme, Grid } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const NewLandingSection5 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box>
      <Grid container spacing={{ xs: 3.75, md: 3.75, lg: 7.5 }}>
        <Grid item xs={12} md={5.7} lg={5}>
          <Stack
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent="center"
            sx={{ px: { xs: 2, md: 0 }, width: '100%', height: '100%' }}
          >
            <Box sx={{ maxWidth: { xs: 435, md: 'fit-content' } }}>
              <img src={'/assets/images/new_landing/image2_3.png'} alt={'image2_3.png'} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6.3} lg={7}>
          <Stack justifyContent="center" sx={{ px: 2, width: '100%', height: '100%' }}>
            <Stack alignItems="flex-start" spacing={3.75}>
              <Typography sx={{ ...theme.typography.p2 }}>
                Don{"'"}t worry about your gardening skills — Khôra Home offers a public library of proven Growing Plans, crafted by our
                community, guaranteeing successful automated growing every time. We also provide memberships tailored to your gardening
                expertise, from fully automated to advanced professional levels.
              </Typography>
              <Button size="large" endIcon={<ArrowRightAltOutlinedIcon />} onClick={() => navigate('/membership')} sx={{ p: 0 }}>
                Learn about Sprout{"'"}s Memberships
              </Button>
              <Typography sx={{ ...theme.typography.p2 }}>
                Khôra Home understands your busy schedule and desire for faster results. That{"'"}s why we offer Clean Hands mode, which
                expedites the harvesting timeline by using starter plants grown by local farmers who are Certified Master Growers. We also
                provide Dirty Hands mode for those who prefer to start with seeding. Khôra Home calculates crop plans for both modes.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewLandingSection5;
