import { useNavigate } from 'react-router-dom';
import { Stack, Grid, Box, Typography, Button, useTheme } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import SpotItem from 'components/SpotItem';

const NewLandingSection4 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box>
      <Grid container spacing={{ xs: 3.75, md: 3.75, lg: 7.5 }}>
        <Grid item xs={12} md={5.7} lg={5} sx={{ display: { xs: 'block', md: 'none' } }}>
          <Stack
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent="center"
            sx={{ px: { xs: 2, md: 0 }, width: '100%', height: '100%' }}
          >
            <Box sx={{ maxWidth: { xs: 435, md: 'fit-content' } }}>
              <img src={'/assets/images/new_landing/image2_2.png'} alt={'image2_2.png'} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6.3} lg={7}>
          <Stack justifyContent="center" spacing={3.75} sx={{ px: 2, width: '100%', height: '100%' }}>
            <Stack alignItems="flex-start" spacing={3.75}>
              <Typography variant="h4">Sprout - The Grower{"'"}s Platform</Typography>
              <Typography sx={{ ...theme.typography.p2 }}>
                Sprout is Khôra Home{"'"}s cloud-based software, offering the following features:
              </Typography>
              <Stack sx={{ pl: 2 }}>
                <SpotItem variant="p2" text={'Seed library'} />
                <SpotItem variant="p2" text={'Growing Plans'} />
                <SpotItem variant="p2" text={'Crop modeling and planning'} />
                <SpotItem variant="p2" text={'Growing crops once, repeatedly, or continuously'} />
                <SpotItem variant="p2" text={'Scheduling for 3, 6, 9, and 12 months'} />
                <SpotItem variant="p2" text={'Shopping and seeding lists'} />
                <SpotItem variant="p2" text={'Plant placement optimization'} />
                <SpotItem variant="p2" text={'Observations'} />
                <SpotItem variant="p2" text={'Costs and profitability management'} />
                <SpotItem variant="p2" text={'Vendor management'} />
                <SpotItem variant="p2" text={'Dashboard with analytics'} />
                <SpotItem variant="p2" text={'Notifications'} />
              </Stack>
              <Button size="large" endIcon={<ArrowRightAltOutlinedIcon />} sx={{ p: 0 }} onClick={() => navigate('/faqs/sprout')}>
                Learn More
              </Button>
              <Button size="large" endIcon={<ArrowRightAltOutlinedIcon />} sx={{ p: 0 }} onClick={() => navigate('/register')}>
                Register
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5.7} lg={5} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent="center"
            sx={{ px: { xs: 2, md: 0 }, width: '100%', height: '100%' }}
          >
            <Box sx={{ maxWidth: { xs: 435, md: 'fit-content' } }}>
              <img src={'/assets/images/new_landing/image2_2.png'} alt={'image2_2.png'} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewLandingSection4;
