import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection7 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Hospitality',
      description:
        'Restaurants, catering companies, and specialty juicers are interested in sourcing fresh, locally grown produce to enhance the quality and flavor of their dishes, making Khôra Home an attractive option.'
    },
    {
      title: 'Baby Food Manufacturing',
      description:
        'Baby food manufacturers aiming to enhance flavor and nutritional value in their products can utilize Khôra Home to grow pesticide-free, nutrient-rich produce suitable for infant consumption.'
    },
    {
      title: 'Pet Food Industry',
      description:
        'Pet and high-breed competition animal food manufacturers looking to source premium-quality ingredients can use Khôra Home to grow fresh, high-nutrient produce to include in their pet food formulations.'
    },
    {
      title: 'Charities and Nonprofits',
      description:
        'Charities dedicated to promoting equality in food access, availability, and community independence can utilize Khôra Home to provide fresh produce to underserved communities and offer education and training opportunities related to sustainable food production.'
    },
    {
      title: 'Military',
      description:
        'Military vessels with limited access to land for extended periods, such as submarines and aircraft carriers, can use Khôra Home to supplement their food supplies with fresh produce, promoting better nutrition and morale among personnel during deployments.'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What business would be interested in having Khôra Home?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Stack spacing={3.75} direction="row" alignItems="center" sx={{ maxWidth: 914 }}>
            <Typography variant="p2">
              Businesses wishing to brand their own food containing or using the high standards of Khôra Home produce to showcase their
              commitment to quality and sustainability.
            </Typography>
            <Box sx={{ height: 160, display: { xs: 'none', md: 'block' } }}>
              <img src={'/assets/images/faq/khorahome_10.png'} alt={'khorahome_10'} style={{ width: 'auto', height: '160px' }} />
            </Box>
          </Stack>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_7_${idx}`} item xs={12} md={4}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection7;
