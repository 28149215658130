import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openSnackbar } from 'store/reducers/snackbar';

// third-party
import jwtDecode from 'jwt-decode';
import Pusher from 'pusher-js';

// reducer - state management
import {
  LOGIN,
  LOGOUT,
  PUSHER,
  NOTIFICATION_LIST,
  NOTIFICATION_COUNT,
  NOTIFICATION_CATEGORY,
  CRITICAL,
  HIDE_CRITICAL
} from 'store/reducers/actions';
import authReducer, { initialState } from 'store/reducers/_auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { setSetupStatus } from 'store/reducers/setupStatus';

const verifyToken = (access_token) => {
  if (!access_token) {
    return false;
  }
  const decoded = jwtDecode(access_token);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem('access_token', access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    localStorage.removeItem('access_token');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| Auth CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const _dispatch = useDispatch();
  const navigate = useNavigate();

  // pusher

  const setNotificationCount = useCallback((notificationCount) => {
    dispatch({
      type: NOTIFICATION_COUNT,
      payload: {
        notificationCount
      }
    });
  }, []);

  const setNotificationCategory = useCallback((notificationCategory) => {
    dispatch({
      type: NOTIFICATION_CATEGORY,
      payload: {
        notificationCategory
      }
    });
  }, []);

  const setNotifications = useCallback(
    (notifications) => {
      const notificationCount = notifications?.filter((item) => !item?.viewed_at)?.length;
      setNotificationCount(notificationCount);
      dispatch({
        type: NOTIFICATION_LIST,
        payload: {
          notifications
        }
      });
    },
    [setNotificationCount]
  );

  const getAllNotification = useCallback(async () => {
    try {
      const res = await axios.get('/noti/all');
      setNotifications(res?.data);
    } catch (err) {
      setNotifications([]);
    }
  }, [setNotifications]);

  const viewAllNotification = useCallback(async () => {
    try {
      const res = await axios.post('/noti/view_all');
      setNotifications(res?.data);
    } catch (err) {
      // todo
      _dispatch(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: { color: 'error' }
        })
      );
    }
  }, [setNotifications, _dispatch]);

  const readOneNotification = useCallback(
    async (ID) => {
      try {
        const res = await axios.post('/noti/read_one/' + ID);
        setNotifications(res?.data);
      } catch (err) {
        // todo
        _dispatch(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: { color: 'error' }
          })
        );
      }
    },
    [setNotifications, _dispatch]
  );

  const readSelectedNotification = useCallback(
    async (ids) => {
      try {
        const res = await axios.post('/noti/read_multi', { ids });
        setNotifications(res?.data);
      } catch (err) {
        // todo
        _dispatch(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: { color: 'error' }
          })
        );
      }
    },
    [setNotifications, _dispatch]
  );

  const readAllNotification = useCallback(async () => {
    try {
      const res = await axios.post('/noti/read_all');
      setNotifications(res?.data);
    } catch (err) {
      // todo
      _dispatch(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: { color: 'error' }
        })
      );
    }
  }, [setNotifications, _dispatch]);

  const unReadOneNotification = useCallback(
    async (ID) => {
      try {
        const res = await axios.post('/noti/unread_one/' + ID);
        setNotifications(res?.data);
      } catch (err) {
        // todo
        _dispatch(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: { color: 'error' }
          })
        );
      }
    },
    [setNotifications, _dispatch]
  );

  const unReadSelectedNotification = useCallback(
    async (ids) => {
      try {
        const res = await axios.post('/noti/unread_multi', { ids });
        setNotifications(res?.data);
      } catch (err) {
        // todo
        _dispatch(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: { color: 'error' }
          })
        );
      }
    },
    [setNotifications, _dispatch]
  );

  const deleteOneNotification = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete('/noti/delete_one/' + ID);
        setNotifications(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: true, data: err?.message };
      }
    },
    [setNotifications]
  );

  const deleteSelectedNotifications = useCallback(
    async (ids) => {
      try {
        const res = await axios.delete('/noti/delete_multi', { data: { ids } });
        setNotifications(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: true, data: err?.message };
      }
    },
    [setNotifications]
  );

  useEffect(() => {
    if (!state.user?.id || !state.privatePusher) {
      state.privatePusher?.unsubscribe('private-Message.' + state.user?.id);
      return;
    }
    const channel = state.privatePusher?.subscribe('private-Message.' + state.user?.id);
    channel?.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (res) => {
      if (!res?.capture_type) getAllNotification();
    });
  }, [state.user?.id, state.privatePusher, getAllNotification]);

  useEffect(() => {
    if (!state.user?.id || !state.publicPusher) {
      state.publicPusher?.unsubscribe('public-Message');
      return;
    }
    const channel = state.publicPusher?.subscribe('public-Message');
    channel?.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', () => {
      getAllNotification();
    });
  }, [state.user?.id, state.publicPusher, getAllNotification]);

  const setPusher = useCallback(
    (access_token) => {
      if (access_token && verifyToken(access_token)) {
        try {
          const privatePusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
            cluster: 'mt1',
            authEndpoint: '/broadcasting/auth',
            auth: {
              headers: {
                Authorization: 'Bearer ' + access_token
              }
            }
          });
          const publicPusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
            cluster: 'mt1'
          });
          dispatch({
            type: PUSHER,
            payload: {
              privatePusher,
              publicPusher
            }
          });
          getAllNotification();
        } catch (err) {
          console.error(err);
          dispatch({
            type: PUSHER,
            payload: {
              privatePusher: null,
              publicPusher: null
            }
          });
        }
      } else {
        dispatch({
          type: PUSHER,
          payload: {
            privatePusher: null,
            publicPusher: null
          }
        });
      }
    },
    [getAllNotification]
  );

  const getMe = useCallback(async () => {
    try {
      const access_token = window.localStorage.getItem('access_token');
      if (access_token && verifyToken(access_token)) {
        setSession(access_token);
        setPusher(access_token);
        const timeInfo = Intl.DateTimeFormat().resolvedOptions();
        const response = await axios.post('/auth/me', { timezone: timeInfo?.timeZone });
        dispatch({
          type: LOGIN,
          payload: { user: response.data }
        });
        _dispatch(
          setSetupStatus({
            status: response.data?.setup_status,
            step: response.data?.current_step
          })
        );
      } else {
        setSession(null);
        setPusher(null);
        dispatch({
          type: LOGOUT
        });
      }
    } catch (err) {
      setSession(null);
      setPusher(null);
      dispatch({
        type: LOGOUT
      });
    }
  }, [_dispatch, setPusher]);

  useEffect(() => {
    getMe();
  }, [getMe]);

  const getUser = useCallback(async () => {
    try {
      const access_token = window.localStorage.getItem('access_token');
      if (access_token && verifyToken(access_token)) {
        const response = await axios.post('/auth/me');
        dispatch({
          type: LOGIN,
          payload: { user: response.data }
        });
      } else {
        setSession(null);
        setPusher(null);
        dispatch({
          type: LOGOUT
        });
      }
    } catch (err) {
      setSession(null);
      setPusher(null);
      dispatch({
        type: LOGOUT
      });
    }
  }, [setPusher]);

  const getMembershipAfterHowIGrow = useCallback(async () => {
    try {
      const access_token = window.localStorage.getItem('access_token');
      if (access_token && verifyToken(access_token)) {
        const response = await axios.post('/auth/me');
        dispatch({
          type: LOGIN,
          payload: { user: response.data }
        });
      } else {
        setSession(null);
        dispatch({
          type: LOGOUT
        });
      }
    } catch (err) {
      setSession(null);
      dispatch({
        type: LOGOUT
      });
    }
  }, []);

  const checkCriticalWarning = useCallback(async () => {
    try {
      const res = await axios.get('/dashboard/check_critical_warning');
      return res?.data;
    } catch (err) {
      return null;
    }
  }, []);

  const handleCriticalClose = useCallback(() => {
    dispatch({
      type: HIDE_CRITICAL
    });
  }, []);

  const login = useCallback(
    async (values) => {
      try {
        const timeInfo = Intl.DateTimeFormat().resolvedOptions();
        const response = await axios.post('/auth/login', {
          ...values,
          timezone: timeInfo?.timeZone
        });
        const { access_token, user } = response.data;
        setSession(access_token);
        setPusher(access_token);
        dispatch({
          type: LOGIN,
          payload: { user }
        });
        _dispatch(
          setSetupStatus({
            status: user?.setup_status,
            step: user?.current_step
          })
        );
        checkCriticalWarning().then((critical) => {
          if (critical) {
            dispatch({
              type: CRITICAL,
              payload: {
                critical
              }
            });
          }
        });
        return { status: true, data: '' };
      } catch (err) {
        if (err?.message == 'Email not verified') {
          navigate('/code_verification', { replace: true, state: { email: values?.email } });
        }
        return { status: false, data: err?.message };
      }
    },
    [_dispatch, setPusher, checkCriticalWarning, navigate]
  );

  const emailCheck = useCallback(async (email) => {
    try {
      const response = await axios.post('/auth/email_check', { email });
      return { status: true, data: response.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const googleLogin = useCallback(
    async (token) => {
      try {
        const timeInfo = Intl.DateTimeFormat().resolvedOptions();
        const response = await axios.post('/auth/google', { token, timezone: timeInfo?.timeZone });
        const { access_token, user } = response.data;
        if (!access_token) {
          return { status: false, data: '', user };
        }
        setSession(access_token);
        setPusher(access_token);
        dispatch({
          type: LOGIN,
          payload: { user }
        });
        _dispatch(
          setSetupStatus({
            status: user?.setup_status,
            step: user?.current_step
          })
        );
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [_dispatch, setPusher]
  );

  const addSocialAdditionalInfo = useCallback(
    async (data) => {
      try {
        const response = await axios.post('/auth/social_additional', data);
        const { access_token, user } = response.data;
        setSession(access_token);
        setPusher(access_token);
        dispatch({
          type: LOGIN,
          payload: { user }
        });
        _dispatch(
          setSetupStatus({
            status: user?.setup_status,
            step: user?.current_step
          })
        );
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [_dispatch, setPusher]
  );

  const register = useCallback(async (values) => {
    try {
      const timeInfo = Intl.DateTimeFormat().resolvedOptions();
      await axios.post('/auth/register', {
        ...values,
        timezone: timeInfo?.timeZone
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const logout = useCallback(() => {
    setSession(null);
    dispatch({ type: LOGOUT });
  }, []);

  const verify = useCallback(async (email, code) => {
    try {
      await axios.post('/auth/verify', { email, code });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const resend = useCallback(async (email) => {
    try {
      await axios.post('/auth/resend', { email });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const forgotPassword = useCallback(async (email) => {
    try {
      await axios.post('/auth/forgot', { email });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const resetPassword = useCallback(async (token, values) => {
    try {
      await axios.post('/auth/reset', { ...values, token });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const closeAccount = useCallback(async (values) => {
    try {
      await axios.post('/auth/close', values);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const guideCheck = useCallback(async (setup_status) => {
    try {
      const res = await axios.post('/status', { setup_status });
      dispatch({
        type: LOGIN,
        payload: { user: res?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateNotificationEmail = useCallback(async (values) => {
    try {
      const res = await axios.post('/setting/user/notification_email', values);
      dispatch({
        type: LOGIN,
        payload: { user: res?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updatePersonalInfo = useCallback(async (values) => {
    try {
      const res = await axios.post('/setting/user/update', values);
      dispatch({
        type: LOGIN,
        payload: { user: res?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateAvatar = useCallback(async (values) => {
    try {
      const res = await axios.post('/setting/user/avatar', values);
      dispatch({
        type: LOGIN,
        payload: { user: res?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updatePassword = useCallback(
    async (values) => {
      try {
        const res = await axios.post('/setting/user/password', values);
        const { access_token, user } = res.data;
        setSession(access_token);
        setPusher(access_token);
        dispatch({
          type: LOGIN,
          payload: { user }
        });
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setPusher]
  );

  const buyMembership = useCallback(async (membership, type) => {
    try {
      const res = await axios.post(`/setting/user/membership/${type}`, { membership });
      dispatch({
        type: LOGIN,
        payload: { user: res?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const trialMembership = useCallback(async (values) => {
    try {
      const res = await axios.post('/setting/user/membership_trial', values);
      dispatch({
        type: LOGIN,
        payload: { user: res?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getMembershipHistory = useCallback(async () => {
    try {
      const res = await axios.get('/setting/user/membership/history');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMembershipTrialDuration = useCallback(async () => {
    try {
      const res = await axios.get('/setting/trial_duration');
      return res?.data?.trial_duration;
    } catch (err) {
      return 0;
    }
  }, []);

  // address management api
  const getCountries = useCallback(async () => {
    try {
      const res = await axios.get('/address/country');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getStatesForCountry = useCallback(async (countryId) => {
    try {
      const res = await axios.get(`/address/state/${countryId}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCitiesForState = useCallback(async (stateId) => {
    try {
      const res = await axios.get(`/address/city/${stateId}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getNewsList = useCallback(async () => {
    try {
      const response = await axios.get('/public/news/post');
      return response.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getOneNews = useCallback(async (newsID) => {
    try {
      const response = await axios.get(`/public/news/post/detail/${newsID}`);
      return response.data;
    } catch (err) {
      return {
        current: null,
        next: null,
        previous: null,
        same_category: []
      };
    }
  }, []);

  const setLightingPlanMode = useCallback(async () => {
    try {
      const response = await axios.post('/setting/user/toggle_lighting_setting');
      dispatch({
        type: LOGIN,
        payload: { user: response?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const verifyEmailAddress = useCallback(async (token) => {
    try {
      await axios.post(`/auth/email_verify/${token}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateUseLink4Setting = useCallback(async (use_link4) => {
    try {
      const response = await axios.post(`/setting/user/use_link4`, { use_link4 });
      dispatch({
        type: LOGIN,
        payload: { user: response?.data }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        getMe,
        getUser,
        getMembershipAfterHowIGrow,
        login,
        emailCheck,
        logout,
        googleLogin,
        addSocialAdditionalInfo,
        register,
        verify,
        resend,
        closeAccount,
        forgotPassword,
        resetPassword,
        updateNotificationEmail,
        updatePersonalInfo,
        updatePassword,
        updateAvatar,
        buyMembership,
        trialMembership,
        getMembershipHistory,
        guideCheck,
        getAllNotification,
        viewAllNotification,
        readOneNotification,
        readSelectedNotification,
        readAllNotification,
        unReadOneNotification,
        unReadSelectedNotification,
        deleteOneNotification,
        setNotificationCount,
        setNotificationCategory,
        deleteSelectedNotifications,
        handleCriticalClose,
        getMembershipTrialDuration,
        getCountries,
        getStatesForCountry,
        getCitiesForState,
        getNewsList,
        getOneNews,
        setLightingPlanMode,
        verifyEmailAddress,
        updateUseLink4Setting
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthContext;
