import { Grid, Stack, Typography, Box, useTheme } from '@mui/material';

const LandingJourneySection = () => {
  const theme = useTheme();
  const titleStyle = {
    fontSize: { xs: '32px', md: '42px' },
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: { xs: '44px', md: '60px' }
  };
  return (
    <Stack>
      <Stack sx={{ maxWidth: 1140, margin: 'auto', pb: { xs: 5, md: 10 }, px: 2.5 }}>
        <Grid container spacing={5} sx={{ pt: { xs: '40px', md: '60px' } }}>
          <Grid item xs={12} md={6}>
            <Stack spacing={5}>
              <Typography sx={{ ...titleStyle }}>Our Journey</Typography>
              <Typography variant="p2m">
                In the vibrant State of Texas, where pride runs deep and hospitality is ingrained in our culture, Khôra Home proudly stands
                tall with our roots in Austin as we reshape the landscape of modern living, change the future of farm-to-table dining, set
                new standards for culinary excellence, and build a vibrant community dedicated to sustainable agriculture and supporting
                local farmers.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack alignItems="center">
              <img
                src={'/assets/images/faq/journey_1.png'}
                alt={'journey_1.png'}
                style={{ width: '100%', height: 'auto', maxWidth: '460px' }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p2">
              The Khôra Home journey began with a bold vision to revolutionize access to fresh, healthy food by developing easy-to-use
              gardening technology for homes and businesses. Our goal was to make growing produce for yourself as common and easy as
              refrigerating food. We envisioned a community committed to growing, sharing their knowledge to enhance everyone{"'"}s
              experience, and increasing food accessibility throughout Texas.
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Stack sx={{ maxWidth: 1140, margin: 'auto', py: { xs: 5, md: 10 }, px: 2.5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={5.5}>
              <Stack alignItems="center">
                <img
                  src={'/assets/images/faq/journey_2.png'}
                  alt={'journey_1.png'}
                  style={{ width: '100%', height: 'auto', maxWidth: '460px' }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6.5}>
              <Stack justifyContent="center" spacing={3.75} sx={{ height: '100%' }}>
                <Typography variant="p2">
                  The first Khôra Home prototype was proudly built by AVI Consulting, Inc., a women-owned company now based in Austin,
                  Texas. Established in April 2000, AVI Consulting, Inc. boasts almost 25 years of expertise in conceptualizing, designing,
                  and implementing highly innovative and intricate technology solutions for global markets.
                </Typography>
                <Typography variant="p2">
                  Through dedication, collaboration, and unwavering persistence, AVI Consulting partnered with esteemed organizations like
                  Massachusetts Institute of Technology, Philips – Signify Lighting, Link4 Corporation, and others to advance the vision and
                  bring to life Khôra Home, Sprout - The Grower{"'"}s Platform, and Khôra Marketplace.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack>
        <Stack sx={{ maxWidth: 1140, margin: 'auto', py: { xs: 5, md: 10 }, px: 2.5 }}>
          <Grid container spacing={3.75}>
            <Grid item xs={12}>
              <Box sx={{ maxWidth: 908 }}>
                <Typography variant="p2">
                  The Khôra Home journey continued with the first Khôra Home Concept Farm built near Lake Travis as a dedicated design and
                  research facility perfecting growing in Khôra Home, providing hands-on learning, and test kitchen crafting and refining
                  recipes with Khôra Home plants.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={0} sx={{ display: { xs: 'block', md: 'none' } }}>
              <Stack alignItems="center">
                <img
                  src={'/assets/images/faq/journey_3.png'}
                  alt={'journey_1.png'}
                  style={{ width: '100%', height: 'auto', maxWidth: '460px' }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6.5}>
              <Stack justifyContent="center" spacing={3.75}>
                <Typography variant="p2">
                  Today, Khôra Home Concept Farm operates as a cottage food business, cultivating plants within Khôra Home and offering a
                  range of harvested produce and specialty foods derived from these plants at local farmers{"'"} markets. With expansion
                  underway, the farm is enhancing its growing capacity to supply specialty grocery stores and restaurants in addition to its
                  current market presence.
                </Typography>
                <Typography variant="p2">
                  In the future, Khôra Home products will achieve widespread recognition for their uniqueness and exceptional quality
                  throughout Texas, earning prominent placement in specialty grocery stores and restaurants alike. The strong brand
                  recognition and demand we cultivate will pave the way for the commercial release of Khôra Home appliances.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Stack alignItems="center">
                <img
                  src={'/assets/images/new_landing/image2_1.png'}
                  alt={'journey_1.png'}
                  style={{ width: '100%', height: 'auto', maxWidth: '460px' }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack>
        <Stack sx={{ maxWidth: 1140, margin: 'auto', pb: { xs: 5, md: 10 }, pt: 5, px: 2.5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={3.5}>
              <Stack alignItems="center">
                <img
                  src={'/assets/images/faq/journey_4.png'}
                  alt={'journey_1.png'}
                  style={{ width: '100%', height: 'auto', maxWidth: '460px' }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={8.5}>
              <Stack justifyContent="center" spacing={3.75} sx={{ height: '100%' }}>
                <Typography variant="p2">
                  As homes and businesses cultivate their own produce, they will contribute to the collective Khôra Home Public Library of
                  growing knowledge and significantly enhancing the diversity of foods available to grow in Khôra Home and sell to retail
                  and wholesale consumers.
                </Typography>
                <Typography variant="p2">
                  The Khôra Home journey represents a commitment to innovation and transformative impact on the lives of Texans. Our vision
                  for the future is ambitious yet grounded in authenticity, aiming to continually exceed expectations of our proud Texan
                  culture.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default LandingJourneySection;
