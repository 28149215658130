import { useNavigate } from 'react-router-dom';
import { Stack, Grid, Box, Typography, Button, useTheme } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import SpotItem from 'components/SpotItem';

const NewLandingSection6 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box>
      <Grid container spacing={{ xs: 3.75, md: 3.75, lg: 7.5 }}>
        <Grid item xs={12} md={5.7} lg={5} sx={{ display: { xs: 'block', md: 'none' } }}>
          <Stack
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent="center"
            sx={{ px: { xs: 2, md: 0 }, width: '100%', height: '100%' }}
          >
            <Box sx={{ maxWidth: { xs: 435, md: 'fit-content' } }}>
              <img src={'/assets/images/new_landing/image2_4.png'} alt={'image2_4.png'} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6.3} lg={7}>
          <Stack justifyContent="center" sx={{ px: 2, width: '100%', height: '100%' }}>
            <Stack alignItems="flex-start" spacing={3.75}>
              <Typography variant="h4">Khôra Marketplace</Typography>
              <Typography sx={{ ...theme.typography.p2 }}>
                Khôra Marketplace is an online farmers market for fresh local foods, unique finds, and city-wide events. We streamline
                traditional farmers market shopping with a convenient, all-in-one platform that fits your schedule.
              </Typography>
              <Stack spacing={1} sx={{ pl: 2 }}>
                <SpotItem variant="p2" text="Pre-order from your favorite vendors in one place-never miss out on the best products." />
                <SpotItem variant="p2" text="Pick up your items at the market or arrange a convenient pick-up time." />
                <SpotItem variant="p2" text="Let us deliver the farmers market to you." />
                <SpotItem variant="p2" text="Enjoy discounts on fresh items that aren't sold over the weekend." />
              </Stack>
              <Typography sx={{ ...theme.typography.p2 }}>
                With Khôra Home, you can effortlessly grow surplus produce and sell it on Khôra Marketplace. The seamless integration makes
                it easy to create and manage listings.
              </Typography>
              <Button size="large" endIcon={<ArrowRightAltOutlinedIcon />} sx={{ p: 0 }} onClick={() => navigate('/faqs/marketplace')}>
                Learn More
              </Button>
              <Button
                size="large"
                endIcon={<ArrowRightAltOutlinedIcon />}
                sx={{ p: 0 }}
                onClick={() => window.open(process.env['REACT_APP_MARKETPLACE_URL'], '_blank')}
              >
                Khôra Marketplace
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5.7} lg={5} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent="center"
            sx={{ px: { xs: 2, md: 0 }, width: '100%', height: '100%' }}
          >
            <Box sx={{ maxWidth: { xs: 435, md: 'fit-content' } }}>
              <img src={'/assets/images/new_landing/image2_4.png'} alt={'image2_4.png'} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewLandingSection6;
